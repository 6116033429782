import React from 'react'
import { graphql } from 'gatsby'
import SimpleReactLightbox from 'simple-react-lightbox'
import SEO from 'components/SEO'
import NewsPostContent from 'components/News/Post'

const NewsPost = ({ data, location }) => {
  const { markdownRemark } = data
  return (
    <>
      <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.excerpt} />
      <SimpleReactLightbox>
        {<NewsPostContent data={markdownRemark} pathname={location.pathname} />}
      </SimpleReactLightbox>
    </>
  )
}

export default NewsPost

export const query = graphql`
  query NewsPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        date
        images {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
      }
      html
      excerpt
    }
  }
`
