import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SRLWrapper } from 'simple-react-lightbox'
import { formatDate } from 'utils'

const NewsPostContent = ({ data, pathname }) => {
  const {
    frontmatter: { title, date, images },
    html,
    excerpt
  } = data
  const options = {
    settings: {
      autoplaySpeed: 0,
      lightboxTransitionSpeed: 0.2,
      disablePanzoom: true
    }
  }

  return (
    <div className="container mx-auto px-8 py-20">
      <Link className="inline-block" to={`/${pathname.split('/')[1]}`}>
        <svg
          className="cursor-pointer transform hover:scale-95 transition-transform duration-300 ease-in-out"
          width="35"
          height="35"
          viewBox="0 0 35 35"
          fill="none"
        >
          <path
            d="M25.5208 17.6216H9.72217"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6215 25.5208L9.72217 17.6215L17.6215 9.72217"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <circle cx="17.5" cy="17.5" r="16.5" stroke="black" strokeWidth="2" />
        </svg>
      </Link>
      <div className="my-9">
        <h1 className="text-3xl md:text-4xl text-vividBlue mb-4">{title}</h1>
        <span className="text-sm">{formatDate(date, 'en-us')}</span>
      </div>
      <div className="text-sm my-16 max-w-982px" dangerouslySetInnerHTML={{ __html: html }} />
      <SRLWrapper options={options}>
        <div className="grid grid-cols-1 place-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          {images.map(({ childImageSharp: { gatsbyImageData } }, index) => {
            return (
              <div
                className="w-56 h-40 rounded-lg overflow-hidden cursor-pointer transform hover:-translate-y-2 transition-transform ease-in-out duration-200"
                key={`image-${index}`}
              >
                <GatsbyImage
                  className="rounded-lg object-cover w-full h-full"
                  image={gatsbyImageData}
                  alt={title}
                  layout="fullWidth"
                  quality="100"
                />
              </div>
            )
          })}
        </div>
      </SRLWrapper>
    </div>
  )
}

export default NewsPostContent
